/** When your routing table is too long, you can split it into small modules **/

import Layout from "@/layout";

const adminRouter = [
  {
    path: "/user",
    component: Layout,
    redirect: "/user",
    children: [
      {
        name: "User",
        path: "",
        component: () => import("@/views/pages/user/index"),
        meta: {
          title: "用户管理",
          icon: "iconfont icon-username",
        },
      },
    ],
  },
  {
    path: "/content",
    component: Layout,
    redirect: "/content/topic",
    name: "content",
    meta: {
      title: "商品管理",
      icon: "iconfont icon-has-favorite",
    },
    children: [
      {
        name: "Advert",
        path: "advert",
        component: () => import("@/views/pages/advert/index"),
        meta: {
          title: "首页广告",
          icon: "iconfont icon-image",
        },
      },
      {
        name: "Activity",
        path: "activity",
        component: () => import("@/views/pages/activity/index"),
        meta: {
          title: "活动商品",
          icon: "iconfont icon-score",
        },
      },
    ],
  },
  {
    path: "/dict",
    component: Layout,
    name: "dict",
    children: [
      {
        name: "Dict",
        path: "dict",
        component: () => import("@/views/pages/dict/index"),
        meta: {
          title: "字典管理",
          icon: "iconfont icon-tags",
        },
      },
    ],
  },
  {
    path: "/school",
    component: Layout,
    name: "school",
    children: [
      {
        name: "School",
        path: "school",
        component: () => import("@/views/pages/school/index"),
        meta: {
          title: "学校管理",
          icon: "iconfont icon-tags",
        },
      },
    ],
  },
  {
    path: "/settings",
    component: Layout,
    redirect: "/settings",
    children: [
      {
        path: "",
        component: () => import("@/views/pages/settings/index"),
        name: "settings",
        meta: {
          title: "系统设置",
          icon: "iconfont icon-setting",
        },
      },
    ],
  },
];
export default adminRouter;
